<template lang="pug">
include ../../../configs/template.pug
div.d-flex.flex-wrap.mt-3.text-left
  div.w-100
    +field-validation('certificate.other_number', 'number', '["required"]')
  div(v-if="actionType === 'add'").w-100
    +select-validation('certificate.ntz', 'etiList', 'passportIssued', 'labelName', '["required"]')
  div.w-100
    +select-validation('certificate.course_training', 'etiCoursesList', 'course', 'labelName', '["required"]')(
      :return-object="actionType === 'edit'")
  div.w-50
    +date-picker-validation('certificate.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]')(
      :max="new Date().toISOString()" min="1900-01-01")
  div.w-50
    +date-picker-validation('certificate.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue", "maxValue"]')(
      :min="certificate.date_start || '1900-01-01'" max="2200-12-31")
  div(v-if="actionType === 'add'").w-100
    +select-validation('certificateType', 'certificateTypesList', 'type', 'labelName', '["required"]')
  div(v-if="actionType === 'add'").w-100
    +select-validation('certificate.status_document', 'listStatuses', 'status', 'labelName', '["required"]')
  div(v-if="actionType === 'add' || sailorDocument.statement").w-100
    FileDropZone(
      :error-messages="$valid.validation($v.mediaFilesArray, ['required'])"
      ref="mediaContent").w-100.mt-1.pa-0
  div.w-100.d-flex.justify-center.mt-3
    v-btn(@click="saveNewCertificate" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { sailorCertificateETI } from '@/mixins/validationRules'
import { mapState, mapActions, mapGetters } from 'vuex'

const initBody = () => (
  {
    other_number: null,
    ntz: null,
    course_training: null,
    date_start: null,
    date_end: null,
    status_document: null
  }
)

export default {
  name: 'SailorCertificationForm',
  // inject: ['closeVerificationStatement'],
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    actionType: { type: String, default: '' },
    sailorDocument: { type: Object, default: () => ({}) },
    verificationSailorID: { type: [ Number, String ] }
  },
  data () {
    return {
      certificate: initBody(),
      certificateType: 0,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['statusById']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      etiList: state => state.directory.educationTraining,
      etiCoursesList: state => state.directory.courses,
      certificateTypesList: state => state.directory.certificateTypes
    }),
    mediaFilesArray () {
      return this.$refs?.mediaContent?.filesArray || []
    },
    listStatuses () {
      const statusesArray = []
      const statusesArrayIDs = [17, 18, 19, 21, 37]
      statusesArrayIDs.forEach(status => statusesArray.push(this.statusById(status)))
      return statusesArray
    },
    dateStartObject () {
      return this.certificate.date_start ? new Date(this.certificate.date_start) : null
    },
    dateEndObject () {
      return this.certificate.date_end ? new Date(this.certificate.date_end) : null
    }
  },
  beforeMount () {
    this.setFieldsAutocomplete()
  },
  validations () { return sailorCertificateETI(this) },
  methods: {
    ...mapActions(['setCertificates', 'updateCertificates', 'getDirectoryObjectAboutUUID']),

    saveNewCertificate () {
      if (this.$v.$invalid) return this.$v.$touch()

      const body = {
        other_number: this.certificate.other_number,
        date_start: this.certificate.date_start,
        date_end: this.certificate.date_end
      }
      if (this.actionType === 'add') {
        body.certificate_type = this.certificateType
        body.course_training = this.certificate.course_training
        body.ntz = this.certificate.ntz
        body.status_document = this.certificate.status_document
      } else {
        body.course_training = this.certificate.course_training.id
      }
      const media = {
        name: 'CertificateETI'
      }
      this.$refs?.mediaContent && (media.files = [...this.$refs.mediaContent.filesArray])

      this.isLoading = true
      this[this.actionType === 'add' ? 'setCertificates' : 'updateCertificates']({ ...this.$route.params, body, media }).then(response => {
        if ([200, 201].includes(response?.code)) {
          if (this.actionType === 'add') {
            this.$notification.success('addedETI')
            this.$parent.isViewAddSlot = false
            this.certificate = initBody()
            this.$v.$reset()
            this.$store.commit('incrementBadgeCount', { child: 'certificateDocument', parent: 'certificateAll' })
          } else {
            this.$parent.$parent.getData()
            this.$notification.success('editedETI')
          }
          if (this.$route.query?.viewEditBlock) this.$router.replace({ query: {} })
        }
      }).finally(() => {
        this.isLoading = false
      })
    },

    setFieldsAutocomplete () {
      if (this.actionType === 'edit') {
        this.certificate = Object.keys(this.certificate).reduce((t, k) => ({ ...t, [k]: this.sailorDocument[k] }), {})
      }
    }
  }
}
</script>
